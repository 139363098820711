.footer {
  margin-top:4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  padding: 0.7rem 0; /* Corrected the padding syntax */
  color: #fff;
  text-align: center;
  position: relative;
  height: 450px;
  background-image: url("../../assets/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: contain; 
  text-align: center;
 
  
  
}

.footer-legals{
  border-top:1px solid  #3C3E41 ;
  width: 80%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}


.legals-list{
  width: 80%;
  max-width: 500px;
  display: flex;
  justify-content: space-evenly;
 }

.lightGreen{
  color: rgb(56, 218, 45);
  font-size: 1rem !important;
  font-weight: 400;
  
cursor: pointer;
}
.bold-600{
  font-weight: 600;

}
.green{
  color: green;
 
  font-size: 0.85rem;
}

.lightGreen:hover{
color: rgb(1, 105, 1);
}

p {
  margin: 0;
  padding: 0;
}


.footer-link{

  color: #FFF;
text-align: center;
font-feature-settings: 'salt' on, 'liga' off;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: bold;
line-height: 100%; /* 16px */
}

.footer-main{
  display: flex;
  padding: 2rem;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-between;
  background-color: #1c2221;
}



.footer-link-div{
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: space-evenly;
  margin: 2rem 0;
  flex-wrap: wrap;
 
  
}

.footer-link{
 
}

.footer-link img{
  width: 25px;
}

.footer-link-icons{
  display: flex;
  justify-content: center;
  gap:20px
}

.contactus-container{
  display: flex;
  gap: 20px;
  flex-direction: column;
  text-align: center;


}
@media screen and (max-width:700px) {
  .footer-main{
    flex-direction: column;
    margin-top: 0;
  }
  .footer-link{
    font-size: 12px;
  }
  .footer-main{
    font-size: 10px;
  }
  .footer-main >div >img{
    width: 85px !important;
  }
  .footer-link-div{
    margin:0 !important;
    width: 100%;
    font-size: 14px !important;
    justify-content: center !important;
  }
  .footer-link img {
width: 10px !important;
  }
  .footer{
    height: 560px;
    /* background-image: url("../../assets/images/footer-bg.svg"); */
   
  }
  .footer-link-icons{
    display: flex;
    justify-content: center;
    gap:20px;
    margin-top: 12px;
  }
  
  .contactus-container{
    display: flex;
    flex-direction: row;
  }
  .footer-legals{
   flex-direction: column;
   gap: 1rem;
   }
   .legals-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
   }
}
@media screen and (max-width:900) {
  .footer{
    height: 560px;
    /* background-image: url("../../assets/images/footer-bg.svg"); */
   
  }

  .footer-link-icons{
    display: none !important;
  }

}