@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

body {
  background: #19191A;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  color: white;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
  padding: 1rem;
  margin: 1rem;
}

.td,
.th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 1rem;
}

.tr:nth-child(odd) {
  background-color: #77c7a7;
}
.tr:nth-child(even) {
  background-color: #23674c;
}

.primary__title {
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  color: rgb(244, 169, 50);
}

.table__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  min-height: 55vh;
  height: 80%;
  width: 60%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.input {
  width: 90%;
  margin: 1rem;
  padding: 0.8rem;
  outline: none;
  font-size: 1.1rem;
}

.screen {
  min-height: 90vh;
  /* background-color: #081e16; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  width: 90%;
  color: rgb(222, 222, 222);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1rem;
}

.submit-btn {
  width: 300px;
  margin-top: 2rem;

  padding: 1rem;
  margin-left: auto;
  margin-right: 3rem;
}

.form {
  color: #fff;
  font-size: 2rem;
}

.banner-hero {
  background: rgba(54, 246, 224, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-height: 85vh;
  width: 95%;
  margin: 1rem;
  margin-top: 2rem;
}
.rounded {
  border-radius: 5px;
}
html {
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hover-grey:hover {
  background-color: grey !important;
}
.twhite {
  color: white !important;
  font-weight: bold !important;
}

.tblack{
  color: black !important;
}
.tcenter {
  text-align: center !important;
}
.center-width {
  width: 95%;
  margin-left: 2.5%;
}

.pool-btn-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: max-content;
  padding: 1rem;
}

.pool-btn{
  width: auto !important;
 
}
/* .banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: #e9eff5;
} */

.banner-left {
  flex: 0.2;
  margin: 1rem;
}

.banner-right {
  flex: 0.8;
  margin-right: 2rem;
  margin-top: 1rem;
}

.banner-right-h {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.label-heading {
  font-size: 3rem;
  font-weight: bold;
  font-style: normal;
  color: rgb(244, 169, 50);
}

.heading-btn {
  background-color: whitesmoke;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  background: #e9eff5;
  color: #081e16;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 1);
}
.heading-btn:hover {
  background-color: #00071a;
  color: white;
  cursor: pointer;
}

.content-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.card {
  padding: 1rem;
  min-width: 220px;
  min-height: 60px;
  border-radius: 20px;
  /* box-shadow: 20px 20px 60px #c6cbd0, -20px -20px 60px #ffffff; */
  color: white;
  margin: 1rem;
  background-color: #00071a;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 1);
  /* word-wrap: break-word; */
}
.card:hover {
  box-shadow: 1px 1px 6px 0px rgba(255, 255, 255, 1);
  cursor: pointer;
}

.label-semi {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.description {
  margin-top: 5rem;
}

.neomorphism {
  border-radius: 50px;
  background: #e9eff5;
  box-shadow: 20px 20px 60px #c6cbd0, -20px -20px 60px #ffffff;
  color: #081e16;
}
.fontS22 {
  font-size: 22px;
}
.tUpper {
  text-transform: uppercase;
}
.shadow {
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 1);
}
.shadow-light {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 1);
}
.shadowGrey {
  box-shadow: 0px 0px 6px 0px #21242b;
}
.border {
  border-top: 2px solid #091e17;
  border-left: 2px solid #091e17;
  border-bottom: 4px solid #091e17;
  border-right: 4px solid #091e17;
}
.borderS {
  border-top: 2px solid #091e17 !important;
  border-left: 2px solid #091e17 !important;
  border-bottom: 2px solid #091e17 !important;
  border-right: 2px solid #091e17 !important;
}
.tShadow {
  box-shadow: 71px 58px 55px -9px rgba(69, 69, 69, 0.75) inset;
}
.cbutton {
  padding: 0.6rem;
  margin: 0 0.5rem 0 0.5rem;
  cursor: pointer;
  outline: none;
  color: rgb(2, 30, 86);
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
}
.cbutton:hover {
  background-color: rgb(244, 169, 50);
  color: #fff;
}
.activeButton {
  background-color: rgb(244, 169, 50) !important;
  color: #fff !important;
}
.rounded20 {
  border-radius: 20px !important;
}
.bg1 {
  background-color: #00071a !important;
}
.bg2 {
  background-color: #002945 !important;
}
.inputField {
  width: 100%;
  background-color: #1a1b1f;
  border-radius: 18px;
  color: white;
  font-size: 24px;
  padding: 15px;
}
.inputfWW {
  background-color: rgb(246, 242, 242);
  border-radius: 10px;
  color: rgb(22, 22, 22);
  font-size: 24px;
  padding: 6px;
  width: 90%;
}
.tlower {
  text-transform: capitalize;
}
.wallet:hover {
  border: 1px solid red !important;
}

.yellow {
  color: yellow !important;
}

.green {
  color: #22BD86 !important;
}

.red{
  color:#F93C3C !important
}

.pink {
  color: white !important;
}

.grey{
  color: #686F7E !important;
  font-weight: 400 !important;
}
.blue {
  color: white !important;
}
.dfelxJsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dfelxalitemC {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}
.dsparound {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.token-page-btn {
  color: rgb(195, 197, 203);
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
}
.token-page-t1 {
  font-size: 16px;
  color: rgb(195, 197, 203);
  font-weight: 600;
}
.token-page-t2 {
  color: white;
  font-size: 22px;
  font-weight: 600;
  font-family: Iner var sans-serif;
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 3px;
}
.chart_btn {
  color: rgb(195, 197, 203);
  padding: 0.1rem 1.5rem;
  cursor: pointer;
  margin: 2px;
  border-radius: 12px;
}
.chart_btn:hover {
  background-color: #00071a;
}
.chart_btn_selected {
  background-color: #00071a;
}
.button-hover:hover {
  background-color: '' !important ;
}
.chart-button {
  padding: 0.2rem 0.5rem !important;
  margin: 0.05rem !important;
  cursor: pointer;
  color: rgb(59, 145, 106) !important;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  border: 1px solid #1C1D20 !important;
  /* min-width: 120px; */
}

.chart-button:hover {
  background-color: rgb(244, 169, 50);
  color: #fff;
}
.graph-container{
  display: flex;
  flex-direction: row;
  width: 95%;
}
.search-input{
  padding: 5px 12px;
  width:9em;
  border: none;
  background: transparent;
  color: white;
  border-radius: 0.5em;
  outline:rgb(59, 145, 106);
}

.transaction-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 
}
@media (max-width: 610px) {
  .chart-button {
    padding: 0 !important;
    margin: 0 0.1rem !important;
  }
  .pool-btn-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    padding: 1rem;
 
  }
  .graph-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
  .pool-btn{
    width: fit-content !important;
   font-size: 10px !important;
  }
  .transaction-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
   
  }
}
@media (max-width: 1440px) {
  .chart-button {
    margin: 5px !important;
  }
  /* .pool-btn{
    width: 122px !important;
  } */
}
@media (max-width: 1024px) {
  .pool-btn-container{
    max-width: 100%;
    justify-content: flex-start;
  }
  .button-search{
    display: block !important;
  }
  .button-search .pool-btn-container:last-child {
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .res-token{
    width: 90% !important;
    min-width: 0 !important;
  }
  .button-search .pool-btn-container:last-child {
    justify-content: center;
  }
  /* .pool-btn{
    width: auto !important;
  } */
}

.glassmorphosism {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  margin-bottom: 1rem;
}
.dashboard-card {
  text-align: center;
  background-color: #1a1b1f !important;
  border-radius: 15px !important;
  cursor: pointer;
}


.token_card-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
 margin: 1rem 0 0 0;
  padding:2rem;
  border:2px solid #282c34;
  width: 95%;
  min-height: 300px;
  border-radius: 10px;
  background-color:#1a1b1f ;
  display: flex;
  flex-direction: column;
  color: #dddddd;
  

}

.nextprevcontainer{

  width: 100%;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #21242b56;
  color: whitesmoke;
  border-radius: 0.5em;
}

.nextprevcontainer button{
  color: white;
  background-color: #00071a;
  margin: 1rem;
  padding: 5px;
  width: 30px;
  border: 1px solid green;
  font-size: 1rem;
  cursor: pointer;
}


.stock-card{
  min-width: 200px;
 border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: #01110b;
  margin: 0.2rem;
  justify-content: center;
  padding: 1rem;
}

.learn-more{
 margin-top: 50px;
  font-weight: 500;
  font-size: 1rem;
  background-color: rgb(38, 95, 70) ;
  padding: 0.5rem;
  width: fit-content;
  border-radius: 8px;
}
.learn-more:hover{
 
   background-color: rgb(51, 126, 94) ;

 }
 .history-link{
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
 }


 tr:hover{
background-color: rgba(0, 0, 0, 0.416);
 }

 .blinkup{
  background-color:rgba(0, 100, 0, 0.572);
 }

 .blinkdown{
  background-color:rgba(124, 1, 1, 0.572);
 }

 .brand-color {
  background-color: #76C8A8;
 }

.main_nav_container {
  display: flex;
  justify-content: space-around;
  /* padding: 1em 1em; */
}

.new_search_input {
  background-color: transparent;
  /* border: 1px solid white; */
  outline: none;
  color: white;
  padding: 0.5em;
  border:none;
}

.search_icon {
  color:#686F7E;
}

.search_input_and_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0.2em;
  /* border: 1px solid white; */

}

.new_search_input::placeholder {
  color: #686F7E;
}

.fxpairs_static_btn {
  background-color: #76C8A8;
  border-radius: 2em;
  color: #1F2A27;
  padding: 0.4em 0.8em;
  border: none;
}

.navigation_container
{
  display: flex;
  padding: 1em 0.5em;
  overflow: auto;
}

.navigation_btns {
  border-radius: 2em;
  border-color: #37383C;
  border: none;
  outline: #37383C solid 1px;
  padding: 0.4em 0.8em;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8em;
  cursor: pointer;
}

.navigation_btns:hover {
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
  background-color: #1C1D20;
}

.navigation_btns > span {
  margin-left: 0.5em;
}

.inside_container_for_responsiveness {
  display: flex;
}

.pecuscan_container {
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
}

.pecuscan_container > a {
  color: #1F2A27;
  background-color: #76C8A8;
  font-size: 13px;
  font-weight: 700;
  padding: 1em 1em;
  border-radius: 2em;
  text-align: center;
}

/* RedSwapBtn */
.RedSwapBtn {
  background-color: transparent;
  display: flex;
  width: 80%;
  height: 36px;
  padding: 10px 19px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #F93C3C;
  flex-shrink: 0;
  color: #F93C3C;
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: -20px;
  cursor: pointer;
}

.RedSwapBtn:hover{
  background-color: #F93C3C;
  color: white;
  transition:0.5s
}

/* NormalSwapBtn */
.NormalSwapBtn {
  background-color: transparent;
  display: flex;
  width: 80%;
  height: 36px;
  padding: 10px 19px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #76C8A8;
  flex-shrink: 0;
  color: #76C8A8;
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: -20px;
  cursor: pointer;
}
.NormalSwapBtn:hover{
  background-color:#22BD86;
  color: white;
  transition:0.5s
}
.transferSwapBtn {
  background-color: transparent;
  display: flex;
  width: 100%;
  height: 36px;
  padding: 10px 19px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #76C8A8;
  flex-shrink: 0;
  color: #76C8A8;
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  cursor: pointer;
}
.transferSwapBtn:hover{
  background-color:#22BD86;
  color: white;
  transition:0.5s
}


@media (max-width: 1200px) {
  .main_nav_container {
    flex-direction: column-reverse;
  }
}