.sendCoins-main {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sendCoins-main h2,
  .sendCoins-main h5 {
    text-align: center;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .sendCoins-main .total-coin-title {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .sendCoins-main label {
    display: block;
    margin: 1rem 0 0.5rem;
    color: #555;
  }
  
  .sendCoins-main select,
  .sendCoins-main input,
  .sendCoins-main textarea,
  .sendCoins-main button {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
  }
  
  .sendCoins-main input[type="file"] {
    padding: 0.5rem;
  }
  
  .sendCoins-main input:disabled {
    background: #e9e9e9;
  }
  
  .sendCoins-main button.primary {

    background: rgb(16, 96, 68);
    color: white;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .sendCoins-main button.primary:hover {
    background: rgb(25, 142, 101);
  }
  
  .sendCoins-main h5 {
    margin: 2rem 0 1rem;
    font-size: 1.2rem;
  }
  
  .sendCoins-main form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .sendCoins-main form + h5 {
    margin-top: 3rem;
  }
  
  .Token-create-panel{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .Token-create-panel button{
    width: 200px;
    margin: 1rem;
    padding: 0.7rem;
    background: rgb(13, 78, 55);
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 10px;
  }

  .Token-create-panel button:hover {
    background: rgb(25, 142, 101);
    
  }