body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}


.recharts-cartesian-axis-tick tspan{
    font-size: 8px;
}

.fast {
    animation:             blink 300ms linear infinite;
        -moz-animation:    blink 300ms linear infinite; 
        -webkit-animation: blink 300ms linear infinite; 
}

.regular {
    animation:             blink 750ms linear infinite;
        -moz-animation:    blink 750ms linear infinite; 
        -webkit-animation: blink 750ms linear infinite; 
}

.slow {
    animation:             blink 1500ms linear infinite;
        -moz-animation:    blink 1500ms linear infinite; 
        -webkit-animation: blink 1500ms linear infinite; 
}

@keyframes             blink { 0% {opacity:0;} 50% {opacity:1;} 100% {opacity:0;} }
    @-moz-keyframes    blink { 0% {opacity:0;} 50% {opacity:1;} 100% {opacity:0;} }
    @-webkit-keyframes blink { 0% {opacity:0;} 50% {opacity:1;} 100% {opacity:0;} }


    /* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 67, 0, 0);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6eba9d00;
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 128, 0, 0)
  }

  ::-webkit-scrollbar-corner {
    background: transparent; /* Color of the corner */
  }