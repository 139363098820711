.news-c1,
.news-c3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  /* flex: 0.25; */
}

.news-c1{
  height: 100vh;
  overflow-x: scroll;
}
.news-c2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  /* flex: 0.4; */
}

.news-c3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: black;
  max-width: 300px;
}

.news-r1 {
  /* flex: 1; */
  /* gap:"20px"; */
  margin-bottom: 1rem;
  min-width: 280px;
  /* max-height: 400px; */
  padding: 25px;
  /* border-radius: 16px; */
  /* flex-shrink: 0; */
  border-radius: 15px;
  background: #1c1d20;
}

.news-r1-news{
    /* min-width: 250px; */
    max-height: fit-content;
    /* border-radius: 16px; */
    border-radius: 15px;
    padding: 0;
    background: #1c1d20;
}



.readMore {
  color: #76C8A8;
  font-weight: 750;
  font-size: 16px;
}

.video_News {
  flex: 1;
  margin: 1rem;
  /* padding: 1rem; */
  
  border-radius: 15px;
  background: #000;
  width: 600px;
  height: 360px;
}
.news_wrapper1 {
  width: 100%;
  height: 1385px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
}

.news_wrapper1::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
.news_wrapper2 {
  width: 100%;
  height: auto;
 
}



.news-r1 > span > p,
.news-r2 > span > p,
.news-r3 > span > p {
  margin: 5px 0;
}
.news-r1 > p,
.news-r2 > p,
.news-r3 > p {
  margin: 0 0 20px 0;
}
.news-r1 > h4,
.news-r2 > h4,
.news-r3 > h4 {
  margin: 5px 0 20px 0;
}
@media (max-width: 768px) {
  .news_section {
    flex-direction: column;
  }
  .video_News {
    width: 85%;
    height: 250px;
  }
  .news-c1 {
    order: 2;
  }
  .news-c2 {
    order: 1;
  }
}
